import React, { Component } from 'react';
import {Link} from 'react-router-dom'
import './style.css'
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
//import team_1 from '../../images/team/1.jpg';
import g1 from '../../images/gallery/g1.jpg';
import g1_2 from '../../images/gallery/g1_2.jpg';
import g1_3 from '../../images/gallery/g1_3.jpg';
import g1_4 from '../../images/gallery/g1_4.jpeg';
import g1_5 from '../../images/gallery/g1_5.jpg';
import g2 from '../../images/gallery/g2.jpg';
import g3 from '../../images/gallery/g3.jpg';
import g4 from '../../images/gallery/g4.jpg';
import g5 from '../../images/gallery/g5.jpg';
import g6 from '../../images/gallery/g6.jpg';
import g7 from '../../images/gallery/g7.jpg';
import g8 from '../../images/gallery/g8.jpg';
import g9 from '../../images/gallery/g9.jpg';
import g10 from '../../images/gallery/g10.jpg';
import g11 from '../../images/gallery/g11.jpg';
import g12 from '../../images/gallery/g12.jpg';
import g13 from '../../images/gallery/g13.jpg';
import g14 from '../../images/gallery/g14.jpg';
import g15 from '../../images/gallery/g15.jpg';
import g16 from '../../images/gallery/g16.jpg';
import g17 from '../../images/gallery/g17.jpg';
import g18 from '../../images/gallery/g18.jpg';
import g19 from '../../images/gallery/g19.jpg';
import g20 from '../../images/gallery/g20.jpg';
import g21 from '../../images/gallery/g21.jpg';
import g22 from '../../images/gallery/g22.jpg';
import g23 from '../../images/gallery/g23.jpg';
import g24 from '../../images/gallery/g24.jpg';
import g25 from '../../images/gallery/g25.jpg';
import g26 from '../../images/gallery/g26.jpg';
import g27 from '../../images/gallery/g27.jpg';
import g28 from '../../images/gallery/g28.jpg';
import g29 from '../../images/gallery/g29.jpg';
import g30 from '../../images/gallery/g30.jpg';
import g31 from '../../images/gallery/g31.jpg';
import g32 from '../../images/gallery/g32.jpg';
import g33 from '../../images/gallery/g33.jpg';
import g34 from '../../images/gallery/g34.jpg';



class Gallery extends Component {
    render() {
        return (
            <div className="wpo-team-area-2">
                <div className="container">
                    <div className="col-l2">
                        <div className="wpo-section-title text-center">
                            <h2>Our Gallery</h2>
                        </div>
                    </div>
                    <div className="row">
                        
                        <div className="col-lg-3 col-md-6 col-12">
                            <div className="wpo-team-single">
                                <div className="wpo-team-img">
                                    <img src={g1} alt=""/>
                                </div>
                                
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                            <div className="wpo-team-single">
                                <div className="wpo-team-img">
                                    <img src={g1_2} alt=""/>
                                </div>
                                
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                            <div className="wpo-team-single">
                                <div className="wpo-team-img">
                                    <img src={g1_3} alt=""/>
                                </div>
                                
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                            <div className="wpo-team-single">
                                <div className="wpo-team-img">
                                    <img src={g1_4} alt=""/>
                                </div>
                                
                            </div>
                        </div>
                        <div className="row">
                        
                        <div className="col-lg-3 col-md-6 col-12">
                            <div className="wpo-team-single">
                                <div className="wpo-team-img">
                                    <img src={g1_5} alt=""/>
                                </div>
                                
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                            <div className="wpo-team-single">
                                <div className="wpo-team-img">
                                    <img src={g6} alt=""/>
                                </div>
                                
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                            <div className="wpo-team-single">
                                <div className="wpo-team-img">
                                    <img src={g7} alt=""/>
                                </div>
                                
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                            <div className="wpo-team-single">
                                <div className="wpo-team-img">
                                    <img src={g8} alt=""/>
                                </div>
                                
                            </div>
                        </div>
                        
                    </div>
                    <div className="row">
                        
                        <div className="col-lg-3 col-md-6 col-12">
                            <div className="wpo-team-single">
                                <div className="wpo-team-img">
                                    <img src={g9} alt=""/>
                                </div>
                                
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                            <div className="wpo-team-single">
                                <div className="wpo-team-img">
                                    <img src={g10} alt=""/>
                                </div>
                                
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                            <div className="wpo-team-single">
                                <div className="wpo-team-img">
                                    <img src={g11} alt=""/>
                                </div>
                                
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                            <div className="wpo-team-single">
                                <div className="wpo-team-img">
                                    <img src={g12} alt=""/>
                                </div>
                                
                            </div>
                        </div>
                        
                    </div>
                    <div className="row">
                        
                        <div className="col-lg-3 col-md-6 col-12">
                            <div className="wpo-team-single">
                                <div className="wpo-team-img">
                                    <img src={g13} alt=""/>
                                </div>
                                
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                            <div className="wpo-team-single">
                                <div className="wpo-team-img">
                                    <img src={g14} alt=""/>
                                </div>
                                
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                            <div className="wpo-team-single">
                                <div className="wpo-team-img">
                                    <img src={g15} alt=""/>
                                </div>
                                
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                            <div className="wpo-team-single">
                                <div className="wpo-team-img">
                                    <img src={g16} alt=""/>
                                </div>
                                
                            </div>
                        </div>
                        
                    </div>
                    <div className="row">
                        
                        <div className="col-lg-3 col-md-6 col-12">
                            <div className="wpo-team-single">
                                <div className="wpo-team-img">
                                    <img src={g17} alt=""/>
                                </div>
                                
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                            <div className="wpo-team-single">
                                <div className="wpo-team-img">
                                    <img src={g18} alt=""/>
                                </div>
                                
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                            <div className="wpo-team-single">
                                <div className="wpo-team-img">
                                    <img src={g19} alt=""/>
                                </div>
                                
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                            <div className="wpo-team-single">
                                <div className="wpo-team-img">
                                    <img src={g20} alt=""/>
                                </div>
                                
                            </div>
                        </div>
                        
                    </div>
                    <div className="row">
                        
                        <div className="col-lg-3 col-md-6 col-12">
                            <div className="wpo-team-single">
                                <div className="wpo-team-img">
                                    <img src={g21} alt=""/>
                                </div>
                                
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                            <div className="wpo-team-single">
                                <div className="wpo-team-img">
                                    <img src={g22} alt=""/>
                                </div>
                                
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                            <div className="wpo-team-single">
                                <div className="wpo-team-img">
                                    <img src={g23} alt=""/>
                                </div>
                                
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                            <div className="wpo-team-single">
                                <div className="wpo-team-img">
                                    <img src={g24} alt=""/>
                                </div>
                                
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                            <div className="wpo-team-single">
                                <div className="wpo-team-img">
                                    <img src={g25} alt=""/>
                                </div>
                                
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                            <div className="wpo-team-single">
                                <div className="wpo-team-img">
                                    <img src={g26} alt=""/>
                                </div>
                                
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                            <div className="wpo-team-single">
                                <div className="wpo-team-img">
                                    <img src={g27} alt=""/>
                                </div>
                                
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                            <div className="wpo-team-single">
                                <div className="wpo-team-img">
                                    <img src={g28} alt=""/>
                                </div>
                                
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                            <div className="wpo-team-single">
                                <div className="wpo-team-img">
                                    <img src={g29} alt=""/>
                                </div>
                                
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                            <div className="wpo-team-single">
                                <div className="wpo-team-img">
                                    <img src={g30} alt=""/>
                                </div>
                                
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                            <div className="wpo-team-single">
                                <div className="wpo-team-img">
                                    <img src={g31} alt=""/>
                                </div>
                                
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                            <div className="wpo-team-single">
                                <div className="wpo-team-img">
                                    <img src={g32} alt=""/>
                                </div>
                                
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                            <div className="wpo-team-single">
                                <div className="wpo-team-img">
                                    <img src={g33} alt=""/>
                                </div>
                                
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                            <div className="wpo-team-single">
                                <div className="wpo-team-img">
                                    <img src={g34} alt=""/>
                                </div>
                                
                            </div>
                        </div>
                        
                    </div>
                </div>
                
            </div>
                
        </div>
        );
    }
}

export default Gallery;       




/*
<li><Link to="/"><i className="fa fa-facebook" aria-hidden="true"></i></Link></li>
                                            <li><Link to="/"><i className="fa fa-twitter" aria-hidden="true"></i></Link></li>
*/

/*
<div className="col-lg-4 col-md-6 col-12">
                            <div className="wpo-team-single">
                                <div className="wpo-team-img">
                                    <img src={team_1} alt=""/>
                                    <div className="social-1st">
                                        <ul>
                                            <li><Link to="/"><i className="fa fa-facebook" aria-hidden="true"></i></Link></li>
                                            <li><Link to="/"><i className="fa fa-twitter" aria-hidden="true"></i></Link></li>
                                            <li><Link to="/"><i className="fa fa-linkedin" aria-hidden="true"></i></Link></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="wpo-team-content">
                                    <h4>Elizabeth Bannet</h4>
                                    <span>Logistic Maneger</span>
                                </div>
                            </div>
                        </div>
*/